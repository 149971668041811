import React from 'react';
import {Link} from 'gatsby';
import {FooterMenuWrap, FooterMenuList} from './footer-menu.stc'

const FooterMenu = () => {
    return (
        <FooterMenuWrap>
            <FooterMenuList>
                <Link to="/" className="no-cursor">Home</Link>
            </FooterMenuList>
            
        </FooterMenuWrap>
    )
}

export default FooterMenu;